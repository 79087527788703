/* General Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App Container */
.App {
  text-align: center;
}

/* Header */
.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

.App-header h1 {
  margin: 0;
  font-size: 2.5em;
}

/* Main Content */
main {
  padding: 20px;
}

section {
  margin: 20px 0;
}

/* Input and Button Styles */
input[type="text"] {
  padding: 10px;
  font-size: 1em;
  border: 2px solid #61dafb;
  border-radius: 4px;
  width: 300px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #21a1f1;
}

/* Bingo Card */
.bingo-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.bingo-card h2 {
  margin-top: 0;
}

.bingo-card p {
  color: #333;
  margin: 5px 0;
  line-height: 1.5;
}

/* Similar Artists */
.similar-artists {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: left;
}

.similar-artists h2 {
  margin-top: 0;
}

.similar-artists ul {
  list-style-type: none;
  padding: 0;
}

.similar-artists li {
  background: #f9f9f9;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.similar-artists li img {
  border-radius: 4px;
  margin-right: 10px;
}

.similar-artists li p {
  margin: 5px 0;
  line-height: 1.5;
}

.similar-artists li a {
  color: #61dafb;
  text-decoration: none;
}

.similar-artists li a:hover {
  text-decoration: underline;
}
