/* Import a cooler font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

:root {
  --default-background: #f9f9f9;
  --alternate-background: #e0e0e0;
  --free-background: #61dafb;
  --selected-background: rgba(255, 0, 0, 0.5);
}

.bingo-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure the card does not overflow the container */
  margin: 20px auto;
  text-align: center;
  font-family: "Roboto", sans-serif; /* Use the custom font */
}

.bingo-card h2 {
  margin-top: 0;
  font-weight: 700; /* Bold heading */
}

.grid {
  display: grid;
  grid-template-columns: repeat(
    5,
    1fr
  ); /* Use flexible units to ensure columns fit within the container */
  gap: 5px;
  width: 100%; /* Make sure grid takes full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.row {
  display: contents;
}

.cell {
  background: var(--default-background);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0; /* Reset height to allow aspect ratio to take effect */
  padding-top: 100%; /* Maintain a square aspect ratio */
  position: relative; /* Position children absolutely */
  overflow: hidden; /* Ensure text doesn't overflow the cell */
  text-align: center; /* Center text horizontally */
  word-wrap: break-word; /* Wrap words to fit the cell */
  font-size: 1em; /* Adjust font size for better fit */
  cursor: pointer; /* Show pointer cursor on hover */
}

.cell span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cell.selected {
  background: var(--selected-background) !important;
}

.cell:nth-child(odd) {
  background: var(--alternate-background);
}

.cell.free {
  background: var(--free-background);
  font-weight: bold;
  color: white;
}

@media (max-width: 600px) {
  .cell {
    font-size: 0.8em; /* Smaller font size for mobile */
  }
}
